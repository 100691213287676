@tailwind base;
@tailwind components;
@tailwind utilities;

// https://fonts.google.com/specimen/Tomorrow#standard-styles
@import url('https://fonts.googleapis.com/css2?family=Tomorrow:ital,wght@0,100;0,300;0,400;0,600;0,700;1,100;1,300;1,400;1,600;1,700&display=swap');

html {
    overscroll-behavior-y: contain;
}

body {
    margin: 0;
    font-family: 'Tomorrow', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #bbc4d2;
    color: #335;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::selection {
    background-color: rgb(14, 44, 85);
    color: rgb(221, 239, 255);
}

@property --deg {
    syntax: '<angle>';
    inherits: false;
    initial-value: 130deg;
}

.loading {
    --deg: 130deg;
    background: linear-gradient(var(--deg), rgb(225, 14, 144), rgb(12, 111, 215), rgb(9, 226, 9));
    animation: move 1.5s linear infinite;
}

@keyframes move {
    0% {
        --deg: 130deg;
    }
    50% {
        --deg: 490deg;
    }
}

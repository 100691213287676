.row {
    background: linear-gradient(90deg, rgb(37, 45, 53) 0%, #343a52 50%, #242c33 100%);
    min-height: 56px;
    &.isMe {
        margin: 0 -16px 0 -16px;
        background: linear-gradient(90deg, #162738 0%, #424a65 50%, #172735 100%);
        padding: 8px 48px;
    }
    &:not(:last-child)::after {
        content: ' ';
        display: block;
        position: absolute;
        background: linear-gradient(90deg, transparent, #46555d, transparent);
        height: 1px;
        width: 100%;
        bottom: 0;
        left: 0;
    }
}

/* .ENDED for when competition ended */
.status {
    transition: 1.5s;

    &:hover {
        &.ENDED,
        &.COMPLETE {
            opacity: 1;
            transition: 0.3s;
        }
    }
    &.ENDED,
    &.COMPLETE {
        opacity: 0;
    }

    > .circle {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: gray;
        box-shadow: 0 0 0 1px #353546;
        margin: 8px;
        transition: 0.4s;

        &.IN_QUEUE {
            background-color: rgb(51, 255, 255);
            animation: blink 4.5s infinite;
        }
        &.IN_PROGRESS {
            background-color: rgb(255, 251, 0);
            animation: blink 0.5s infinite;
        }
        &.COMPLETE {
            background-color: rgb(63, 194, 63);
        }
        &.SKIP {
            background-color: black;
        }
        &.FAIL {
            background-color: rgb(196, 61, 61);
        }
    }
}

@keyframes blink {
    from {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2) rotate(90deg);
        border-radius: 25%;
        opacity: 0.8;
    }
    to {
        transform: scale(1) rotate(180deg);
    }
}

.wrap {
    display: flex;
    margin: 32px auto;
    max-width: 900px;
}

.mainPart {
    flex: 1;
}

.progressWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dot,
.activeDot {
    width: 8px;
    height: 8px;
    margin: 8px;
    background-color: silver;
}

.activeDot {
    width: 16px;
    height: 16px;
}

.active1 {
    background-color: #38d4a1;
}

.active2 {
    background-color: #f2bf3d;
}

.active3 {
    background-color: #24c2f3;
}

.active4 {
    background-color: #325aaf;
}

.active5 {
    background-color: #03346c;
}

.icon {
    width: 48px;
    max-height: 48px;
    margin-right: 24px;
}

.stepWrap {
    display: flex;
    align-items: center;
}

.finalStep {
    text-align: center;
    .stepWrap {
        justify-content: center;
    }

    .icon {
        width: 88px;
        max-height: 88px;
    }
}

.contentWrap {
    max-width: 700px;
    margin: 0 auto;
}

.title {
    margin-top: 32px;
    font-size: 1.5rem;
}

.description {
    margin-top: 16px;
    margin-bottom: 64px;
    color: #c2c3c8;
}

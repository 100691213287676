.svg {
    display: inline-block;
    transform-origin: center;
    animation: rotate 5s infinite linear;
    polygon {
        stroke-dasharray: calc(50 / 4);
        animation: dash 3.2s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 100;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
